<template>
    <div class="page">
        <div id="top" class="search_wap">
            <div class="a-inline-block">
                 <div class="a-inline-block">
                <a-input v-model="key" class="sInput minInput" placeholder="根据名称搜索"/>
                <a-select v-model="selStatus" style="width:100px">
                    <a-select-option value="">全部</a-select-option>
                     <a-select-option :value="index" v-for="(item,index) in statusList" :key="index">
                            {{item}}
                        </a-select-option>
                </a-select>
                <a-icon type="search" style="margin:0 20px" @click="onSearch"/>
            </div>
                <!-- <a-input-search v-model="key" class="sInput" placeholder="请输入关键词" @search="onSearch"/> -->
            </div>
        </div>
        
        
        <div v-if="this.pagination.total" style="text-align: right;margin-right: 20px;">当前数据条数{{this.pagination.total}}条</div>
         <div v-infinite-scroll="getData"
               infinite-scroll-disabled="dataLoadStop"
               infinite-scroll-distance="50">
        <div class="lists" v-for="(item,index) in data" :key="index">
            <div class="list_row">
                <div class="flex">
                    <div><label for="">线索名称：</label>{{item.name}}</div>
                    <div><label for="">状态：</label>{{item.status_text}}</div>
                </div>
                <div class="flex">
                    <div><label for="">客户名称：</label>{{item.customerid}}</div>
                   <div><label for="">联系人：</label>{{item.customeruser}}</div>
                </div>
                 <div class="flex">
                    <div><label for="">联系方式：</label>{{item.customertel}}</div>
                 </div>
                  
                <div class="flex">
                    <div><label for="">跟进人：</label>{{item.sname}}</div>
                    <div><label for="">时间：</label>{{item.created}}</div>
                </div>
            </div>
            <div class="btn_con">
                <a-button type="primary" size="small" ghost @click="edit(item.id)">编辑</a-button>
                <!-- <a-button type="primary" size="small" ghost @click="openAllocation(item.id)">线索分配</a-button> -->
                <!-- <a-popconfirm title="确定要转商机吗?"  @confirm="() => toBus(item.id)">
                    <a-button type="danger" size="small" ghost>转商机</a-button>
                </a-popconfirm> -->
                 <!-- <a-button type="danger" size="small" ghost @click="showGiveUp(item.id)">放弃</a-button> -->
            </div>
        </div>
         
         <a-modal v-model="visible" :width="400" title="线索分配" @ok="clueAllot">
            <div class="addBox">
                <div class="add_title">请选择跟进人</div>
                <a-select v-model="onUid"  class="add_inp " style="width: 250px">
                    <a-select-option :value="item.userid" v-for="(item,index) in staff" :key="index">
                        {{item.name}}
                    </a-select-option>
                </a-select>
            </div>
        </a-modal>
        <a-modal v-model="ShowGive" title="放弃线索" @ok="submitGiveUp">
            <div class="addBox">
                <div class="add_title">请输入放弃原因及备注</div>
                <a-textarea v-model="giveUpText" />
            </div>
        </a-modal>
         <!-- <a-pagination
                v-if="data.length>0"
                class="page_select"
                v-model="pagination.current"
                :total="pagination.total"
                @change="pagesChange"
            /> -->
              <!-- <div class="list-load-end" style="text-align:center;padding-top:25px;padding-bottom:50px">
                <span>{{dataLoadNomore ? "--没有更多数据了--" : "加载中..."}}</span>
            </div> -->
            
            <a-pagination v-if="pagination.total" style="text-align: center;" @change="onChange" v-model="pagination.current" :total="pagination.total" />
            <div v-if="pagination.total==0" class="list-load-end" style="text-align:center;padding-top:25px;padding-bottom:50px">
                <span>--暂无数据--</span>
            </div>
        </div>
    </div>
</template>
<script>
// import $ from "jquery";
import {requestXml,getDateTime} from '../../../assets/js/request';
import infiniteScroll from "vue-infinite-scroll";
export default {
     directives: {infiniteScroll},  
    data() {
        return {
            // 搜索的内容
            key: "",
            data: [],
            statusList: ['未处理','已跟进','转商机','已放弃'],
            staff:[],  //员工列表
            visible:false,  //线索分配弹框
            onCid:'', //选中的线索id
            onUid:'', //选中的员工id
            ShowGive:false, //放弃弹框
            giveUpText:'', //放弃原由
            selStatus:'',
            // 分页
            dataLoadStop: false,  //列表数据加载停止状态
            dataLoadNomore: false,    //列表数据加载是否完成
            pagination: {
                current: 1,
                total: 100,
                pageSize: 10, // 默认每页显示数量
                showSizeChanger: true, // 显示可改变每页数量
               pageSizeOptions: ['10', '20', '50', '100'], // 每页数量选项
                showQuickJumper: true,
                // showTotal: total => `Total ${total} items`, // 显示总数
                onChange: (current) => this.pagesChange(current), //点击当前页码
                onShowSizeChange: (current, pageSize) => {
                    //设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
                    console.log(pageSize);
                    this.showSizeChange(current, pageSize);
                },
            },
        };
    },
    
    beforeRouteEnter(to, from, next) {
      next(vm=>{
        vm.fromPath = from.path
      })
    },
    beforeDestroy(){
        sessionStorage.setItem('curpage',JSON.stringify({'search':this.key, 'status':this.selStatus,'page':this.pagination}))
    },
    mounted: function(){
        
        if(this.fromPath == "/scrm_wap/clue/clueEdit") {
            var data=JSON.parse(sessionStorage.getItem('curpage'))
            sessionStorage.removeItem('curpage')
            this.pagination=data.page
            this.key=data.search
            this.selStatus=data.status
        }
        this.getData();
        this.getStaff();
    },
    methods:{
        
        onChange(){
            window.location.href='#top'
            this.getData();
        },
         // 分页
        pagesChange(current) {
            //current参数表示是点击当前的页码，
            this.pagination.current = current;
            this.getData(); //向后端发送请求
        },
        showSizeChange(current, pageSize) {
            console.log(current, pageSize);
            this.pagination.current = current;
             this.pagination.pageSize = pageSize;
            this.getData(); //向后端发送请求
        },
        // 获取任务列表
        getData() {
            this.dataLoadStop = true;
            requestXml("/jieb/clue/showclue","POST",(res) => {
                for(let i=0;i<res.list.length;i++){
                    res.list[i].created = getDateTime(res.list[i].created);
                    res.list[i].status_text = this.statusList[Number(res.list[i].status)];
                }
                this.data=res.list
                // this.data = this.data.concat(res.list);
                // if(res.page.maxpage>this.pagination.current){
                // this.pagination.current += 1
                // this.dataLoadStop = false
                // }else{
                // this.dataLoadNomore = true
                // }
                this.pagination.total = Number(res.page.total);
                this.pagination.pageSize = Number(res.page.pagesize);
            },{'search':this.key, 'status':this.selStatus, page: {
                        curpage: this.pagination.current,
                        pagesize: this.pagination.pageSize,
                    }
                    })
        },
        // 搜索
        onSearch(){
            this.data=[];
            this.dataLoadNomore=false;
            this.pagination.current=1;
            this.getData();
        },
        // 获取员工列表
          getStaff(){
            requestXml("/scrm/Staff/getMinList","POST",(res) => {
                this.staff=res;
            })
        },
        // 编辑
        edit(id){
              this.$router.push({
                path: "/scrm_wap/clue/clueEdit",
                query: {
                    id: id,
                }
            });
        },
         //打开分配弹框
        openAllocation(id){
            this.onCid=id;
            this.visible=true;
        },
        // 分配线索
        clueAllot(){
            requestXml("/jieb/clue/saveuid","POST",(res) => {
                if(res=='success'){
                    // this.$message.success('分配成功');
                    this.onCid='';
                    this.onUid='';
                    this.visible=false;
                    this.getData();
                 }else{
                    // this.$message.info('分配失败，请重试');
                 }

            },{"id": this.onCid,'uid':this.onUid})
        },
        // 转商机
        toBus(id){
             requestXml("//jieb/clue/savestatus_","POST",(res) => {
                if(res=='success'){
                    // this.$message.success('转为商机成功');
                    this.onCid='';
                    this.onUid='';
                    this.visible=false;
                    this.getData();
                 }else{
                    // this.$message.info('转为商机失败');
                 }

            },{"id": id})
        },
        //打开放弃弹框
        showGiveUp(id){
            this.ShowGive=true;
            this.onCid=id;
        },
        // 放弃线索
        submitGiveUp(){
            // console.log(this.giveUpText);
              requestXml("/jieb/clue/savestatus","POST",(res) => {
                if(res=='success'){
                    // this.$message.success('已放弃');
                    this.onCid='';
                    this.giveUpText='';
                    this.ShowGive=false;
                    this.getData();
                 }else{
                    // this.$message.info('放弃失败');
                 }

            },{"id": this.onCid,'reason':this.giveUpText})
        },
        
    }
};
</script>
<style>
   .minInput{
        width: 200px;
        margin-left: 15px;
        margin-right: 15px;
    }
  .page_select {
        position: fixed;
        bottom: 0px;
        display: flex;
        justify-content: center;
        width: 100%;
        background: #fff;
    }
    .search_wap{
        text-align: left;
        margin-bottom: 15px;
        height: 40px;
        line-height: 40px;
        background-color: #fff;
    }
    .search_wap .ant-input-search{
        width: 90vw;
        margin: 0 5vw;
    }

    .lists{
        width: 92%;
        /* height: 26rem; */
        padding: 3rem;
        margin: 15px auto;
        background-color: #fff;
        border-radius: 10px;
        color: #202020;
    }
    /* .lists .list_row div{
        height: 5rem;
        line-height: 5rem;
        text-align: left;
    } */
    .lists .list_row .flex{
        justify-content: space-between;
    }
    

    .btn_con{
        text-align: left;
    }
    .btn_con button{
        color: #36A3FF;
        border-color: #36A3FF;
        margin: 1rem 1rem 0 0;
    }  

    
</style>
